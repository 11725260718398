import Img from "gatsby-image"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Project = ({ name, img, href }) => {

    return (
        <div className="relative flex flex-1 flex-grow border border-black">
            <Img fluid={img} className="absolute w-full" />
            <a href={href}>
                <div className="absolute w-full h-full bg-black opacity-75 hover:opacity-50 top-0 left-0">
                    <p className="text-white font-sans font-bold absolute right-0 bottom-0 mb-1 mr-1" style={{width: 'fit-content', height:'fit-content'}}>{name}</p>
                </div>
            </a>
        </div>
    )
}

export default Project
