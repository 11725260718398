import { useStaticQuery, graphql } from "gatsby"

export const useImages = () => {
  const query = useStaticQuery(
    graphql`
      query img {
        winky: file(relativePath: { eq: "winky.png" }) {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fitness: file(relativePath: { eq: "fitness.png" }) {
            childImageSharp {
              fluid(maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    `
  )
  return query
}