import { faBriefcase, faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "gatsby";
import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Project from "../components/project";
import { useImages } from "../hooks/images";
import { faJava, faJs, faHtml5 } from "@fortawesome/free-brands-svg-icons";



const radius = '120px'
const Skill = ({ name, logo }) => {
  const style = {
    width: radius, height: radius
  };

  return (
    <div>
      <div className="rounded-full flex flex-col m-2 bg-gray-900" style={style}>
        <div className='mx-auto my-auto text-bold text-white'>{logo}</div>
      </div>
      <p className="mx-auto text-center font-semibold skill">{name}</p>
    </div>
  )
}

const IndexPage = () => {

  const { winky, fitness } = useImages()
  return (
    <Layout>
      <SEO title="Home" />
      <div className="flex mb-16">
        <div className="ml-auto right-0">
          <Link to="/contact#contact_form">
            <button className="bg-transparent font-sans whitespace-no-wrap hover:bg-black text-black font-semibold hover:text-gray-400 px-2 border border-black hover:border-transparent rounded-full">Contact me</button>
          </Link>
        </div>
      </div>

      <div className="flex flex-row flex-wrap">

        <div className="flex flex-col md:flex-1 mb-4">
          <h2 className="text-xs uppercase mb-4">Top Skills</h2>
          <div className="flex flex-wrap flex-row self-center" style={{ width: 'fit-content' }}>
            <Skill name="Java" logo={<FontAwesomeIcon icon={faJava} size="3x" />} />
            <Skill name="Javascript" logo={<FontAwesomeIcon icon={faJs} size="3x" />} />
            <Skill name="HTML/CSS" logo={<FontAwesomeIcon icon={faHtml5} size="3x" />} />
            <Skill name="Web Marketing" logo={<FontAwesomeIcon icon={faFunnelDollar} size="3x" />} />
          </div>
        </div>
        <div className="flex flex-wrap flex-col md:content-end md:ml-auto right-0">
          <div style={{ width: 'fit-content' }}>
            <h2 className="text-xs uppercase">Main Tools</h2>
            <ul className="divide-y divide-gray-600">
              <li className="bg-yellow-400 mb-0 font-sans p-1 text-xl" style={{ width: 'fit-content' }}>React</li>
              <li className="bg-yellow-400 mb-0 font-sans p-1 text-xl" style={{ width: 'fit-content' }}>Spring</li>
              <li className="bg-yellow-400 font-sans p-1 text-xl" style={{ width: 'fit-content' }}>TailwindCSS</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-xs uppercase">Experience</h2>
        <VerticalTimeline className="font-sans">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#1a202c', color: '#fff' }}            
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date="2019 - present"
            dateClassName="text-gray-800  mx-2"
            iconStyle={{ background: '#1a202c', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faBriefcase} style={{ width: 'auto' }} />}
          >
            <h3 className="vertical-timeline-element-title">FrontEnd Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">São Paulo, SP</h4>
            <p>
              FrontEnd Development with ReactJS
          </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - present"
            dateClassName="text-gray-800 mx-2"
            iconStyle={{ background: '#1a202c', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faBriefcase} style={{ width: 'auto' }} />}
          >
            <h3 className="vertical-timeline-element-title">FullSatck Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">São Paulo, SP</h4>
            <p>
              FullSatck development with Java and Angular
          </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#1a202c', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            dateClassName="text-gray-800  mx-2"
            date="2011 - 2019"
            iconStyle={{ background: '#1a202c', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faBriefcase} style={{ width: 'auto' }} />}
          >
            <h3 className="vertical-timeline-element-title">FullSatck Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Campinas, SP</h4>
            <p>
              FullSatck Development with Java, HTML and CSS; Leading role
          </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <div>
        <h2 className="text-xs uppercase">Projects</h2>
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <Project name="Winky Site" img={winky.childImageSharp.fluid} href='https://winkyperfumaria.com.br' />
          </div>
          <div className="flex">
            <Project name="Fitness Calculators" img={fitness.childImageSharp.fluid} href='https://fitness-calc.netlify.app/' />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
